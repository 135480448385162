import { Route } from 'react-router-dom';
import Menu from './Menu';
import Logo from './Logo';
import {useEffect, useState} from "react";
import Axios from "axios";

const FrontRoute = ({ component: Component, ...props }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { path } = props;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const img_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    window.addEventListener("resize", function () {
      setWidth(window.innerWidth);
    });
  }, [])

  const fetcher = async (url, params) => {
    try {
      const res = await Axios.get(`${baseUrl + url}`, params);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      console.log("Error", err);
    }
    return [];
  };

  const componentProps = {
    width: width,
    baseUrl: baseUrl,
    img_url: img_url,
    fetcher: fetcher,
    ...props
  }

  return <Route
    {...props}
    render={(props) => <>
      <header className={`${path === "/" ? "header no_border" : "header"}`}>
        <Logo/>
        <Menu {...componentProps} />
      </header>
      <Component {...componentProps} />
      <footer className="footer">
        <span>©2025 新展室內裝修有限公司 Xin Zhan Interior Design.</span>
      </footer>
    </>}
  />
}

export default FrontRoute;